import React, { createContext, useContext } from 'react';

export const DomainContext = createContext();

export function DomainProvider({ children }) {
    const hostname = window.location.hostname;

    let value = {
        isAffexy: hostname.startsWith('affexy') || hostname.startsWith('localhost') || hostname.startsWith('beta') || hostname.startsWith('gamma'),
        isBps: hostname.startsWith('bps') || hostname.startsWith('localhost') || hostname.startsWith('beta') || hostname.startsWith('gamma'),
        isPzu: hostname.startsWith('pzu') || hostname.startsWith('localhost') || hostname.startsWith('beta') || hostname.startsWith('gamma'),
        isEnea: hostname.startsWith('enea') || hostname.startsWith('localhost') || hostname.startsWith('beta') || hostname.startsWith('gamma'),
        isKnowledgeSea: hostname.startsWith('knowledgesea') || hostname.startsWith('localhost') || hostname.startsWith('beta') || hostname.startsWith('gamma'),
    }

    return <DomainContext.Provider value={value}>{children}</DomainContext.Provider>;
}

export function useDomain() {
    return useContext(DomainContext);
}
